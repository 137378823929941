import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "@components/layout";
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import Animation modules
import { motion } from "framer-motion"

// Import SVG
import hatena_icon from '@images/hatena_icon.svg'; // ？マークの代わりのダミー
import about_icon1 from '@images/about_icon1.svg'; // 
import about_icon2 from '@images/about_icon2.svg'; // 
import about_icon3 from '@images/about_icon3.svg'; // 
import about_icon4 from '@images/about_icon4.webp'; // 
import about_icon5 from '@images/about_icon5.svg'; // 
import about_icon6 from '@images/about_icon6.svg'; // 
import about_icon7 from '@images/about_icon7.svg'; // 
import about_icon8 from '@images/about_icon8.svg'; // 
import about_icon9 from '@images/about_icon9.svg'; // 

import about_img01 from '@images/about_img01.svg'; // 
import about_img02 from '@images/about_img02.svg'; // 

import about_gno1 from '@images/g_no1.svg'; // 
import about_gno2 from '@images/g_no2.svg'; // 
import about_yno1 from '@images/y_no1.svg'; // 
import about_yno2 from '@images/y_no2.svg'; // 
import about_yno3 from '@images/y_no3.svg'; // 
import about_yno4 from '@images/y_no4.svg'; // 






import topphone from '@images/phone-image.webp';
import topimage01 from '@images/top-image01_2.webp';
import abouttokuchou01 from '@images/about_tokuchou01.webp';
import aboutImg01 from '@images/about_img01.webp';
import aboutImg01_sp from '@images/about_img01_sp.webp';

// 関数呼び出し
import { FixedFoot } from "@components/footer.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定


const PropAbout = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
 
  // Seo情報設定
  const pathName = 'beginner';
  const seoTag = SeoContents({ Path:pathName });

  let setArray;
  let pushArray = [];
  const pickupPath = "/";
  const LinkBeginner = "/beginner#how";
  const LinkAbout = "/about#how";
  
  
  

  return (
  <>
    <Layout>
      {seoTag}
<div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl relative md:h-[300px] xl:overflow-visible overflow-hidden">
          <div className="pb-10 pt-0 md:pt-10  max-w-[800px] md:absolute md:left-5 md:top-[0%]">
            <h2 className="md:text-5xl text-3xl font-black mb-10 text-left pt-5">初めての方へ</h2>
            <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">Just tradeについて</h3>
            <p className="md:text-base text-base  font-black  md:max-w-[480px] xl:max-w-[600px]">
              2 STEPのチャレンジをクリアすることで<br></br>
              Just tradeの資金最大4,000万円の取引ができるチャンス！
            </p>
          </div>
          <div className="max-w-[650px] md:absolute md:right-[-23%] md:top-10">
               <motion.div className=" mx-auto text-center md:block md:mt-10 xl:mt-[0px] md:ml-[200px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
                 <img className="mb-5 mx-auto h-auto" alt="" src={topimage01}/>
               </motion.div>
          </div>
        </div>

        </div>
              {/* サブメニュー */}
        <div className="bg-[#F6F6F6] "><a id="how" className="jump_point"></a>
          <div className="max-w-5xl mx-auto flex px-5">
             <a href={LinkBeginner} className="md:text-base text-xs text-center py-4 px-2 md:px-10 w-1/2 bg-white mr-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">Just tradeの特徴</a>
             <a href={LinkAbout} className="md:text-base text-xs text-center py-4 px-2 md:px-10 w-1/2 bg-white ml-3 font-semibold border-b-[15px] border-white text-[#1F55A6] ">プロップファームとは？</a>
           </div>
        </div>

       {/* 特徴 */}
      <div className="max-w-xl mx-auto md:max-w-5xl mt-[70px] px-5">
        <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6]">プロップファームとは？</h3>
        <h4 className="text-lg mb-7  font-semibold">欧米で急成長しているビジネスモデル、ディーリングのプロ集団「プロップファーム」</h4>
        <p className="mb-[100px]">プロップファーム（Prop firm）とは、トレーダーに資本を提供し、その見返りとして取引時に発生した利益の一部を得ることができる会社のことです。
        <br></br>Just tradeでは２段階の審査基準を設けており、通過し認定されたトレーダーには、利益のうち最大80％を分配しております。
         </p>
      </div>

 <div className="bg-[#F6F6F6] py-5">
   <div className="max-w-xl mx-auto md:max-w-5xl mt-[70px] px-5 " >
      <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6]">ＷＩＮ－ＷＩＮの関係</h3>

      <div className="flex md:justify-between  flex-wrap  mb-[30px]  border-[10px] md:px-[70px] px-[30px] py-[40px] border-[#eee] mb-10 rounded-lg">
        <div>
        <h4 className="md:text-3xl text-2xl mb-10  font-semibold text-[#5EBAA1] text-left">プロップファームの<br className="block md:hidden"></br>メリット</h4>
        <ul className="mb-5 text-xl md:text-2xl  md:pr-[300px]">
        <li className="text-left  mb-9 md:mb-3 font-bold flex"><img src={about_gno1} alt="" className="inline  pr-4 pb-0 "></img>利益の追求</li>
        <li className="text-left mb-9 md:mb-3 font-bold flex"><img src={about_gno2} alt="" className="inline pr-4 pb-0 "></img>認定トレーダーの教育</li>
        </ul>
        </div>
        <div className="mx-auto">
        <p><img className="mx-auto h-auto  max-w-[180px] m-5" alt="" src={about_img01}/></p>
        </div>
      </div>
      <div className="flex md:justify-between  flex-wrap mb-[30px]  border-[10px] md:px-[70px] px-[30px] py-[40px] border-[#eee] mb-10 rounded-lg">
        <div>
        <h4 className="md:text-3xl text-2xl mb-10  font-semibold text-[#EFAA00] text-left">トレーダーのメリット</h4>
        <ul className="mb-5   text-lg  md:text-xl md:text-[18px] ">
        <li className="text-left mb-9 md:mb-3 font-bold flex"> <img src={about_yno1} alt="" className="inline pr-4 pb-0 "></img>企業の資本を使ってレバレッジをかけて取引が可能<br className="block md:hidden"></br>（利益の最大化）</li>
        <li className="text-left mb-9 md:mb-3 font-bold flex"> <img src={about_yno2} alt="" className="inline pr-4 pb-0 "></img>取引時に生じた損失を<br className="block md:hidden"></br>企業が負担</li>
        <li className="text-left mb-9 md:mb-3 font-bold flex"><img src={about_yno3} alt="" className="inline pr-4 pb-0 "></img>自身の取引を試せる環境</li>
        <li className="text-left mb-9 md:mb-3 font-bold flex"><img src={about_yno4} alt="" className="inline pr-4 pb-0 "></img>認定トレーダーとしての<br className="block md:hidden"></br>ブランド価値</li>
          </ul>
        </div>
        <div className="mx-auto">
         <p><img className="mx-auto h-auto  max-w-[200px] md:mt-[60px] mt-[30px]" alt="" src={about_img02}/></p>
        </div>
      </div>


 

    </div>
  </div>



 
   <div className="max-w-xl mx-auto md:max-w-5xl mt-[70px] px-5">
      {/* 特徴詳細1*/}
      <div className="md:flex justify-between block mb-[70px]">
         <div className="mb-5">
           <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5 pl-3 font-semibold">Just tradeが選ばれる理由とは</h4>
           <div className="md:hidden block mb-5 rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6]  mx-auto flex items-center "> 
            <p className=" max-w-[70px] mx-auto">
              <img className="mb-5 mx-auto h-auto pr-4" alt="" src={abouttokuchou01}/>
            </p>
            </div>
           <p>Just tradeでは、取引時に使用する資金は全て当社で負担しているため、世界中のトレーダーにストレスなくトレードをしていただけます。また、スピーディで安定したFX取引を実現するため、「入出金の迅速化」や「カスタマーサポートの品質向上」にも常に取り組んでおります。
            <br></br><br></br>
             このような利便性を第一に考えた各種のサービスを整えていることにより、世界各地のトレーダーに愛用いただけるプロップファームを目指しております!
            <br></br>
           </p>
         </div>


         <div >
            <div className="md:flex hidden rounded-full h-[180px] w-[180px] bg-[#F6F6F6] font-bold text-[#1F55A6] md:ml-10 mx-auto flex items-center "> 
            <p className=" md:max-w-[90px] mx-auto">
              <img className="mb-5 mx-auto h-auto pr-4" alt="" src={abouttokuchou01}/>
            </p>
            </div>
         </div>
       </div>


       <div className="flex md:justify-around justify-center flex-wrap xl:mb-[100px] mb-[70px]">
          <div className="max-w-[350px]  xl:max-w-[300px] mb-10 xl:mb-[30px] bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mt-[17px] mx-auto h-auto  max-w-[60px]" alt="" src={about_icon1}/></p>
            <h4 className="text-lg text-center text-[#1F55A6] font-semibold">国内発のプロップファーム</h4>
          </div>
          <div className="max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mt-[13px] mx-auto h-auto  max-w-[70px]" alt="" src={about_icon2}/></p>
            <h4 className="text-xm leading-7 text-center text-[#1F55A6] font-semibold">海外のプロップファームと<br></br>比較してチャレンジコースが豊富</h4>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mx-auto h-auto  max-w-[60px]" alt="" src={about_icon3}/></p>
            <h4 className="text-xm leading-7  text-center text-[#1F55A6] font-semibold">自己取引を細かく分析できる<br></br>最先端のツール</h4>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mt-[8px] mx-auto h-auto  max-w-[60px]" alt="" src={about_icon4}/></p>
            <h4 className="text-xm leading-7 text-center text-[#1F55A6] font-semibold">世界で最も利用されている<br></br>取引プラットフォームMT5に対応</h4>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mt-[10px]  mx-auto h-auto  max-w-[100px]" alt="" src={about_icon5}/></p>
            <h4 className="text-lg text-center text-[#1F55A6] font-semibold">日本語/英語での<br></br>手厚いサポート</h4>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full w-full">
          <p ><img className="mb-5 mx-auto h-auto  max-w-[50px]" alt="" src={about_icon6}/></p>
            <h4 className="text-lg text-center text-[#1F55A6] font-semibold">高配当な利益分配</h4>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mx-auto h-auto  max-w-[60px]" alt="" src={about_icon7}/></p>
            <h4 className="text-lg text-center text-[#1F55A6] font-semibold">Desk Feeなどの<br></br>手数料一切なし</h4>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mx-auto h-auto  max-w-[40px]" alt="" src={about_icon8}/></p>
            <h4 className="text-lg text-center text-[#1F55A6] font-semibold">Just tradeの<br></br>認定トレーダーとしての認知</h4>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#F6F6F6] p-6 rounded-xl w-full">
          <p ><img className="mb-5 mx-auto h-auto  max-w-[70px]" alt="" src={about_icon9}/></p>
            <h4 className="text-lg text-center text-[#1F55A6] font-semibold">魅力的な<br></br>パートナープログラム</h4>
          </div>
        </div>    

      
  </div>



        {/* 固定フッター */}
        {fixedItem}
      </Layout>
  </>
  )
}
export default PropAbout

// モーダル用funciton
export function ModalItem( item ) {

  // (※変更不要)モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])
  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])
  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    const startYOffset = React.useRef(UseScrollPosition())
    startYOffset.current = window.pageYOffset // スクロール位置の左上のY軸座標
    // 調整　モーダル中身（テキスト以外）の設定
    return(
      <div id="modal" className="modal !z-20 text-black w-full text-right" onClick={(event)=>{event.stopPropagation()}}>
        {/* ここでモーダル表示位置を調整 */}
        <div className="absolute left-1/2 w-full mt-[32%] max-w-[320px] -ml-[160px] touch-none" style={{top: startYOffset.current}}> 
          {/* 出力データ */}
          <div className="!text-base absolute w-full bg-white z-20 max-w-[326px]">{item}</div>
          {/* この中のエリア押すと閉じる、閉じるボタン用*/}
          <button onClick={props.onClick} className="w-[30px] h-[30px] bg-black top-0 z-20 relative">
            <div className="!text-base text-white">×</div>
          </button>
        </div>
        {/*この中のエリア押すと閉じる、透過背景用 */}
        <div className="">
          <button onClick={props.onClick} className="w-full bg-gray-700 absolute top-[0px] left-5 h-[8030px] z-10 opacity-50 touch-none"></button>
        </div>
      </div>
    )
  }
  // 親コンポーネント（ボタン）
  return (
    <>
      <div>
        <button onClick={(event)=>{openModal(event)}} className=""> 
          <span className={"" + (isModalOpen? " hidden" :"")}>
            <img alt="" src={hatena_icon}/> {/* 調整　？マークアイコンに差し替える */}
          </span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}} /> :""}
        </button>
      </div>
    </>
  );
}

// (※変更不要)モーダルスクロール位置取得用function
function UseScrollPosition() {
  const isProcessing = useRef(false)
  const [positionY, setPositionY] = useState(0)
  useEffect(() => {
    const handler = () => {
      if (isProcessing.current) return
      isProcessing.current = true
      window.requestAnimationFrame(() => { // Window.requestAnimationFrame()でpositionYステートの更新を間引く
        isProcessing.current = false
        setPositionY(window.scrollY)
      })
    }
    document.addEventListener('scroll', handler, { passive: true }) // スクロールイベントの登録
    return () => { // スクロールイベントの解除
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return positionY // 取得したスクロール量をreturn
}
